import React from 'react'
import Helmet from 'react-helmet'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset-advanced'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'

import pageWinnerBG from '../images/page-bg-black.svg'
import facebookLogoIcon from '../images/facebook.svg'

const theme = {
  primaryColor: '#008332',
  secondaryColor: '#919191',
  colorWhite: '#fff',
  colorBlack: '#000',
  colorGreen: '#008132',
  textColor: '#1a1715',
  backgroundColor: '#f7f7f7',
  fontMetaPro: `'ff-meta-web-pro', 'Helvetica Neue', 'Helvetica', sans-serif`,
  fontLato: `'Lato', sans-serif`,
}

const LayoutWrapper = styled.div`
  ${(props) =>
    props.page === 'zwyciezcy'
      ? `
    background-image: url(${pageWinnerBG});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  `
      : `
    background-color: ${theme.backgroundColor};
  `}

  .sticky-outer-wrapper {
    position: relative;
    transition: ease 0.2s all;
    z-index: 999999;
  }

  .fullpage-wrapper {
    background-color: #000;
    width: 100% !important;
    transform: none !important;
    height: 100vh !important;
  }

  .fp-section {
    width: 100% !important;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    transition: all 0.7s ease-in-out;

    &.active {
      .introColBottom,
      .qualityTextMiddle,
      .businessColDesc,
      .awardsColLeft {
        opacity: 0;
        @keyframes animateText {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        animation-name: animateText;
        animation-duration: 3s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
      }
    }
  }

  .fp-section.active {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
`

const ModalBackground = styled(BaseModalBackground)`
  z-index: 200;
`

const LayoutFacebookLink = styled.a`
  position: fixed;
  top: 120px;
  right: -37px;
  display: block;
  width: 150px;
  height: 150px;
  background-image: url(${facebookLogoIcon});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  transition: right 0.3s ease;
  z-index: 150;
  @media (min-width: 1200px) and (max-width: 1599px) {
    right: -34px;
    width: 135px;
    height: 135px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    right: -32px;
    width: 125px;
    height: 125px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    right: -30px;
    width: 120px;
    height: 120px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: 110px;
    right: -28px;
    width: 115px;
    height: 115px;
  }
  @media (max-width: 575px) {
    top: 85px;
    right: -20px;
    width: 80px;
    height: 80px;
  }
  @media (max-width: 340px) {
    top: 80px;
    right: -18px;
    width: 75px;
    height: 75px;
  }
  &:hover {
    right: -32px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      right: -30px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      right: -29px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      right: -27px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      right: -25px;
    }
    @media (max-width: 575px) {
      right: -17px;
    }
    @media (max-width: 340px) {
      right: -16px;
    }
  }
`

const GlobalStyle = createGlobalStyle`
  ${reset};

  body {
    font-family: ${(props) => props.theme.fontMetaPro};
    font-weight: 500;

    &.openMenu {
      overflow: hidden;
    }
  }
  .sticky-outer-wrapper {
    &.active {
      nav {
        /* background-color: rgb(255, 255, 255, 0.6); */
      }
    }
  }
  .sticky-inner-wrapper {
    z-index: 5000!important;
  }
`

export default ({ children, page, title, description }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Helmet>
      <title>{title}</title>

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.niedajsieprzeoczyc.pl" />
      <meta
        property="og:image:url"
        content={`https://www.niedajsieprzeoczyc.pl${require('../images/og-image.jpg')}`}
      />
      <meta property="og:image:width" content="2654" />
      <meta property="og:image:height" content="1354" />
      <link rel="stylesheet" href="https://use.typekit.net/hrk7xvv.css" />
    </Helmet>

    <ModalProvider backgroundComponent={ModalBackground}>
      <LayoutWrapper name="top" page={page}>
        {children}
        <LayoutFacebookLink
          href="https://facebook.com/REPXPERT.Polska/"
          target="_blank"
          rel="noopener noreferrer"
        />
      </LayoutWrapper>
    </ModalProvider>
  </ThemeProvider>
)
